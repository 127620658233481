exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-post-tsx-content-file-path-opt-build-repo-content-blog-hello-world-index-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/content/blog/hello-world/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-opt-build-repo-content-blog-hello-world-index-md" */),
  "component---src-templates-post-tsx-content-file-path-opt-build-repo-content-blog-how-to-perform-developer-interview-index-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/content/blog/how-to-perform-developer-interview/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-opt-build-repo-content-blog-how-to-perform-developer-interview-index-md" */),
  "component---src-templates-post-tsx-content-file-path-opt-build-repo-content-blog-k-8-s-recreate-pv-and-pvc-index-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/content/blog/k8s-recreate-pv-and-pvc/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-opt-build-repo-content-blog-k-8-s-recreate-pv-and-pvc-index-md" */),
  "component---src-templates-post-tsx-content-file-path-opt-build-repo-content-blog-limit-offset-vs-page-number-page-size-index-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/content/blog/limit-offset-vs-pageNumber-pageSize/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-opt-build-repo-content-blog-limit-offset-vs-page-number-page-size-index-md" */),
  "component---src-templates-post-tsx-content-file-path-opt-build-repo-content-blog-linux-kill-by-port-number-index-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/content/blog/linux-kill-by-port-number/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-opt-build-repo-content-blog-linux-kill-by-port-number-index-md" */),
  "component---src-templates-post-tsx-content-file-path-opt-build-repo-content-blog-move-docker-data-index-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/content/blog/move-docker-data/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-opt-build-repo-content-blog-move-docker-data-index-md" */),
  "component---src-templates-post-tsx-content-file-path-opt-build-repo-content-blog-spa-nginx-caching-index-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/content/blog/spa-nginx-caching/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-opt-build-repo-content-blog-spa-nginx-caching-index-md" */),
  "component---src-templates-post-tsx-content-file-path-opt-build-repo-content-blog-ws-1006-error-handling-index-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/content/blog/ws-1006-error-handling/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-opt-build-repo-content-blog-ws-1006-error-handling-index-md" */),
  "component---src-templates-post-tsx-content-file-path-opt-build-repo-content-blog-yaml-for-js-devs-index-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/content/blog/yaml-for-js-devs/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-opt-build-repo-content-blog-yaml-for-js-devs-index-md" */)
}

